import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {appURLs} from "@/app/constants.mjs";
function _createMdxContent(props) {
  const _components = {
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Located in a nuclear power plant, Nuke offers a multi-level layout with both above-ground and underground playing areas. Bombsite A features a silo, and Bombsite B is located one floor below, offering distinct tactical approaches. Its industrial aesthetics and intricate vent system provide a challenging environment that tests players' spatial awareness."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite A:"
      }), " Featuring the signature silo and multiple entry points, Bombsite A is a maze of industrial machinery and catwalks. The overhead rafters provide vertical play opportunities."]
    }), "\n", _jsxs("div", {
      className: "images",
      children: [_jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/nuke_a_site.webp`
      }), _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/nuke_a_site_2.webp`
      })]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite B:"
      }), " Located a floor below A, Bombsite B is characterized by its tight corridors and vent access. The control room and reactor add to the challenging layout."]
    }), "\n", _jsxs("div", {
      className: "images",
      children: [_jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/nuke_b_site.webp`
      }), _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/nuke_b_site_2.webp`
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
