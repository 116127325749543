import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {appURLs} from "@/app/constants.mjs";
function _createMdxContent(props) {
  const _components = {
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Anubis invites players into a beautifully rendered ancient Egyptian temple complex. Every inch of the map is a blend of authentic hieroglyphs, majestic statues, and towering pillars. Tactically, the map offers an intricate maze of tight corners, hidden alcoves, and high-visibility areas, providing a varied backdrop for strategizing."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite A:"
      }), " Bathed in a mystical golden light, this site is ornamented with grand statues and intricate carvings. Defensive spots are plentiful, yet the multiple entry points give attackers numerous strategies for a successful plant."]
    }), "\n", _jsx("div", {
      className: "images",
      children: _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/anubis_a_site.webp`
      })
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite B:"
      }), " A subterranean chamber dimly lit by torches, offering stone barricades and dark corners for defenders to hold or ambush. Attackers will find the site challenging to breach without well-coordinated utility."]
    }), "\n", _jsxs("div", {
      className: "images",
      children: [_jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/anubis_b_site.webp`
      }), _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/anubis_b_site_2.webp`
      }), _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/anubis_b_site_3.webp`
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
