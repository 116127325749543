import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 21, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Re-tired visblocker from T spawn to mid"
      }), "\n", _jsx(_components.li, {
        children: "Blocked unintended wallbang"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 21, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Slight visibility tweak from T spawn through mid"
      }), "\n", _jsx(_components.li, {
        children: "Improved visibility into B back site from upper tunnels"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "June 10, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Flipped B site doors"
      }), "\n", _jsx(_components.li, {
        children: "Simplified background when looking from B tunnels towards truck in mid"
      }), "\n", _jsx(_components.li, {
        children: "Changed fog start distance from 1000 to 512, changed max density from .2 to .4"
      }), "\n", _jsx(_components.li, {
        children: "Raised windows by A site ramp to make characters stand out more"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 29, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Opened up skybox from B halls towards site"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 29, 2018"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Removed one-way wallbang from CT spawn up to cat"
      }), "\n", _jsx(_components.li, {
        children: "Fixed some minor bugs"
      }), "\n", _jsx(_components.li, {
        children: "Fix for mip bias exploit"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "November 8, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Increased overall luminance 20% for all Kasbah materials and models for visibility."
      }), "\n", _jsx(_components.li, {
        children: "Fixed missing textures on some polygons of tarp model."
      }), "\n", _jsx(_components.li, {
        children: "Fixed flashbangs not blinding player through chain-link fences."
      }), "\n", _jsx(_components.li, {
        children: "Fixed edging around Kasbah alcove window which had shadowing artifacts."
      }), "\n", _jsx(_components.li, {
        children: "Adjusted penetration value for wood doors."
      }), "\n", _jsx(_components.li, {
        children: "Fixed wallbang values on Mechanique Generale."
      }), "\n", _jsx(_components.li, {
        children: "Fixed ambient lighting values at CT spawn entrance."
      }), "\n", _jsx(_components.li, {
        children: "Smoothed out clipping on block stack at Mid."
      }), "\n", _jsx(_components.li, {
        children: "Fixed some models that were embedded in the ground."
      }), "\n", _jsx(_components.li, {
        children: "Fixed dev text on loading screen."
      }), "\n", _jsx(_components.li, {
        children: "Fixed cover props at B site that would expose player feet."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 27, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Adjusted bullet penetration value of wooden doors"
      }), "\n", _jsx(_components.li, {
        children: "Smoothed out ground displacements for more predictable grenades"
      }), "\n", _jsx(_components.li, {
        children: "Adjusted shadow ambient color temperature"
      }), "\n", _jsx(_components.li, {
        children: "Clip improvements to curbs"
      }), "\n", _jsx(_components.li, {
        children: "Refined stairs at Cat to Short for movement"
      }), "\n", _jsx(_components.li, {
        children: "Refined Window cutout model on B Bombsite to remove head shaped shadow"
      }), "\n", _jsx(_components.li, {}), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 24, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Performance optimizations on low-end and medium-end computers."
      }), "\n", _jsx(_components.li, {
        children: "Removed windows and awning on Catwalk for visibility."
      }), "\n", _jsx(_components.li, {
        children: "Moved door awning on Short A for player movement."
      }), "\n", _jsx(_components.li, {
        children: "Fixed slit in boxes in Lower Tunnels."
      }), "\n", _jsx(_components.li, {
        children: "Removed small port hole windows from towers at Short A for visibility."
      }), "\n", _jsx(_components.li, {
        children: "Removed grain sack from Bombsite A."
      }), "\n", _jsx(_components.li, {
        children: "Removed awnings from Salon on Long."
      }), "\n", _jsx(_components.li, {
        children: "Grenade Clipped stone trim pieces."
      }), "\n", _jsx(_components.li, {
        children: "Fixed room at CT Spawn eating weapons."
      }), "\n", _jsx(_components.li, {
        children: "Moved windows and conduits from wall looking from CT Ramp to Short A."
      }), "\n", _jsx(_components.li, {
        children: "Fixed a number of small holes in the world."
      }), "\n", _jsx(_components.li, {
        children: "Fixed fade distance on barrel at Mid."
      }), "\n", _jsx(_components.li, {
        children: "Upped light value on a number of windows for visibility."
      }), "\n", _jsx(_components.li, {
        children: "Lowered noise and contrast on some texture sets for visibility."
      }), "\n", _jsx(_components.li, {
        children: "Fixed non-precise clipping on dumpsters at Outside Tunnels."
      }), "\n", _jsx(_components.li, {
        children: "Set ‘surfaceproperty’ on Cars to ‘metalvehicle’."
      }), "\n", _jsx(_components.li, {
        children: "Addressed visibility on Pit."
      }), "\n", _jsx(_components.li, {
        children: "Removed rollup door on Salon for visibility."
      }), "\n", _jsx(_components.li, {
        children: "Fixed some noisy walls in Tunnels for visibility."
      }), "\n", _jsx(_components.li, {
        children: "Addressed some visibility issues in Back B Plat."
      }), "\n", _jsx(_components.li, {
        children: "Upped contrast for some cover props at Bombsite B."
      }), "\n", _jsx(_components.li, {
        children: "Fixed clip that players could get stuck on in Pit Plat."
      }), "\n", _jsx(_components.li, {
        children: "Updated radar to reflect building at Car."
      }), "\n", _jsx(_components.li, {
        children: "Fixed errant displacement at Side Pit."
      }), "\n", _jsx(_components.li, {
        children: "Added a few rooftop faces that can be seen while boosting."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 20, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed sun not showing.."
      }), "\n", _jsx(_components.li, {
        children: "Smoothed out terrain by CT spawn to allow smoking off from X-Box."
      }), "\n", _jsx(_components.li, {
        children: "Removed taxi sign from certain car models."
      }), "\n", _jsx(_components.li, {
        children: "Cleaned up alpha painting on Tunnels building for player reads."
      }), "\n", _jsx(_components.li, {
        children: "Added window shutter above Catwalk."
      }), "\n", _jsx(_components.li, {
        children: "Fixed glowing CT player models on low end settings."
      }), "\n", _jsx(_components.li, {
        children: "Adjusted fog values."
      }), "\n", _jsx(_components.li, {
        children: "Cleaned up player reads from CT Mid up toward Suicide."
      }), "\n", _jsx(_components.li, {
        children: "Improved collision of cover props at B Bombsite."
      }), "\n", _jsx(_components.li, {
        children: "Fixed bad surface property sounds on cover props at B."
      }), "\n", _jsx(_components.li, {
        children: "Some clipping refinements."
      }), "\n", _jsx(_components.li, {
        children: "Fixed a few wall-bang bugs on buildings."
      }), "\n", _jsx(_components.li, {
        children: "Grenade clips added to CT spawn."
      }), "\n", _jsx(_components.li, {
        children: "Fixed small walls at Outside Tunnels not being reliably jump-able."
      }), "\n", _jsx(_components.li, {
        children: "Adjusted a number of models that were not lined up properly."
      }), "\n", _jsx(_components.li, {
        children: "Fixed interpenetrating barrels at Mid."
      }), "\n", _jsx(_components.li, {
        children: "Fixed some crates at A there were hanging players up."
      }), "\n", _jsx(_components.li, {
        children: "Smoothed out wall behind Car at A."
      }), "\n", _jsx(_components.li, {
        children: "Fixed displacement that had too high an angle at CT Ramp."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 3, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed various spots where C4 could get stuck"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "November 29, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Removed barrels from Dust2 radar."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "November 28, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Removed barrels outside of doors leading into B site"
      }), "\n", _jsx(_components.li, {
        children: "Blocked visibility through some props around A site"
      }), "\n", _jsx(_components.li, {
        children: "Fixed some spots where C4 could get stuck"
      }), "\n", _jsx(_components.li, {
        children: "Fixed some minor graphical bugs"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 3, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed a spot near mid doors where C4 could get stuck"
      }), "\n", _jsx(_components.li, {
        children: "Fixed some texture bugs in A site"
      }), "\n", _jsx(_components.li, {
        children: "Raised lamp near T spawn towards mid"
      }), "\n", _jsx(_components.li, {
        children: "Added commemorative grafitti"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 9, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed 30 exotic spots where C4 could get stuck[1]"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "April 15, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed a bug where a spot in Long A had the wrong radar name."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 31, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Opened up skybox around Long A double doors, Catwalk and Short"
      }), "\n", _jsx(_components.li, {
        children: "Improved visibility around blue car in Long A"
      }), "\n", _jsx(_components.li, {
        children: "Fixed render distance on a crate in CT spawn"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 8, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed bullet penetration through arch models in tunnels"
      }), "\n", _jsx(_components.li, {
        children: "Fixed collision for door top of mid"
      }), "\n", _jsx(_components.li, {
        children: "Added Goose to Goose"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 2, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Expanded bomb plant zone at Bombsite A to include outside of boxes"
      }), "\n", _jsx(_components.li, {
        children: "Smoothed out collision on door to Bombsite B"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 10, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Replaced all game-play affecting double doors with the new metal ones."
      }), "\n", _jsx(_components.li, {
        children: "Fixed some bad geometry in various places that allowed bullet penetration when it shouldn’t."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 1, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed some spots where C4 could get stuck."
      }), "\n", _jsx(_components.li, {
        children: "Reduced post processing effects."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 26, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Removed some small gaps between crates in Bombsite B"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 6, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed tiny gaps between boxes in Bombsite A"
      }), "\n", _jsx(_components.li, {
        children: "Fixed killer box in Bombsite B"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 5, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Removed dark texture near tunnel stairs."
      }), "\n", _jsx(_components.li, {
        children: "Removed sky collision over building near T spawn."
      }), "\n", _jsx(_components.li, {
        children: "Covered up shadow that looked like a player near CT spawn."
      }), "\n", _jsx(_components.li, {
        children: "Fixed invisible ledges on curved corners."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 3, 2013"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Improved visibility"
      }), "\n", _jsx(_components.li, {
        children: "Bug fixes"
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
