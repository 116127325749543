import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 2, 2023"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed optimization regression"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 25, 2023"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Trimmed Ivy at B long."
      }), "\n", _jsx(_components.li, {
        children: "Reduced size of trim at B Long."
      }), "\n", _jsx(_components.li, {
        children: "Ruins are slightly less ruined."
      }), "\n", _jsx(_components.li, {
        children: "Adjusted clipping at Ruins and B long."
      }), "\n", _jsx(_components.li, {
        children: "Adjusted shape of Boat in Canal."
      }), "\n", _jsx(_components.li, {
        children: "Lowered the water grates at the back of Canal."
      }), "\n", _jsx(_components.li, {
        children: "Adjusted grenade clipping around bombsites."
      }), "\n", _jsx(_components.li, {
        children: "Minor optimizations."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 16, 2022"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed some spots where guns would fall through the world near canal"
      }), "\n", _jsx(_components.li, {
        children: "Fixed grenades falling through floor on A site fountain"
      }), "\n", _jsx(_components.li, {
        children: "Fixed surface properties on models to prevent bullet penetration"
      }), "\n", _jsx(_components.li, {
        children: "Adjusted grenade clips throughout map"
      }), "\n", _jsx(_components.li, {
        children: "Adjusted player clipping throughout map"
      }), "\n", _jsx(_components.li, {
        children: "Reduced size of trim at mid"
      }), "\n", _jsx(_components.li, {
        children: "Added collision to signs"
      }), "\n", _jsx(_components.li, {
        children: "Closed various pixel gaps"
      }), "\n", _jsx(_components.li, {
        children: "Minor optimizations"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "November 18, 2022"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Anubis has been added to Active Duty Group."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 27, 2022"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added nade clips on mid bridge to prevent nades getting stuck."
      }), "\n", _jsx(_components.li, {
        children: "Fixed nade physics on stairs in sewer."
      }), "\n", _jsx(_components.li, {
        children: "Fixed boost near canal."
      }), "\n", _jsx(_components.li, {
        children: "Added collision to large stone blocks above canal."
      }), "\n", _jsx(_components.li, {
        children: "Clipping and visual polish (Thanks Joaokaka1998!)."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 7, 2022"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added a hip cover on the plateau near B main."
      }), "\n", _jsx(_components.li, {
        children: "Clipped the large stone cover on CT mid for smoother movement."
      }), "\n", _jsx(_components.li, {
        children: "Added a scaffolding on A catwalk to prevent the boost through the window to T stair at the canal. (Thanks poseidonskiy rofls)."
      }), "\n", _jsx(_components.li, {
        children: "Allowed players to silently drop down from A long."
      }), "\n", _jsx(_components.li, {
        children: "Fixed stones colliding with the tarp on A catwalk."
      }), "\n", _jsx(_components.li, {
        children: "Removed stone at A sewer."
      }), "\n", _jsx(_components.li, {
        children: "Aligned floating pillar on A with the floor."
      }), "\n", _jsx(_components.li, {
        children: "Removed left-over clips on A main. (Thanks Hentie!)."
      }), "\n", _jsx(_components.li, {
        children: "Removed clips on the stairs that bounce off grenades (Thanks Guidetti & dr!zzle)."
      }), "\n", _jsx(_components.li, {
        children: "Clipping improvements (Thanks dr!zzle!)."
      }), "\n", _jsx(_components.li, {
        children: "Cleaned up clipping under the bridge (Thanks Fnugz for your sacrifice)."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "August 16, 2022"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Re-added to game."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "May 3, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Removed from game."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 17, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reskinned the signs and information boards"
      }), "\n", _jsx(_components.li, {
        children: "Fixed the wallbang through the trim on the mid building between double door and the CT house"
      }), "\n", _jsx(_components.li, {
        children: "Added some grenade clips to prevent weird grenade bouncing on A heaven"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 17, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Widened A main entrance"
      }), "\n", _jsx(_components.li, {
        children: "Simplified A connector area"
      }), "\n", _jsx(_components.li, {
        children: "Got rid of the deep corner below the windows on A"
      }), "\n", _jsx(_components.li, {
        children: "Got rid of the wall near the pillar at Waterfalls A site and reduced some corners to hide in as a CT"
      }), "\n", _jsx(_components.li, {
        children: "Some other minor things 3kliksphillip will probably find out"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 31, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added to Scrimmage along with Chlorine."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "April 10, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Moved from Scrimmage to Competitive mode."
      }), "\n", _jsx(_components.li, {
        children: "Fixed water reflection issues."
      }), "\n", _jsx(_components.li, {
        children: "Fixed all reports regarding clipping and unintended boost spots."
      }), "\n", _jsx(_components.li, {
        children: "Improved performance."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
