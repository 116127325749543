import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 16, 2022"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Increased bomb radius to 650 from 500"
      }), "\n", _jsx(_components.li, {
        children: "Flattened out cubbies in red house top of mid"
      }), "\n", _jsx(_components.li, {
        children: "Pushed back CT spawn 256 units, delaying CTs ~1 sec"
      }), "\n", _jsx(_components.li, {
        children: "Compressed blocky things on B site, to create longer sightlines and more space to move around"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 26, 2022"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed some boost exploits"
      }), "\n", _jsx(_components.li, {
        children: "Fixed some minor graphical bugs"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "June 15, 2022"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Remove cratestack outside of “cave” location."
      }), "\n", _jsx(_components.li, {
        children: "Fixed pixelgap from inside “cave” towards T mid."
      }), "\n", _jsx(_components.li, {
        children: "Fixed ground clipping bugs in mid causing players to go slightly airborne."
      }), "\n", _jsx(_components.li, {
        children: "Smoothed out ground outside of “cave” down towards B ramp."
      }), "\n", _jsx(_components.li, {
        children: "Blocked archeological dig inside of “cave”."
      }), "\n", _jsx(_components.li, {
        children: "Smoothed out corners in “donut” room."
      }), "\n", _jsx(_components.li, {
        children: "Added additional cover near pillar on B."
      }), "\n", _jsx(_components.li, {
        children: "Blocked one sidepath exit towards A site, widened remaining exit."
      }), "\n", _jsx(_components.li, {
        children: "Fixed some visual glitches when looking out towards mid from “donut” room."
      }), "\n", _jsx(_components.li, {
        children: "Reduced wallbang damage through scaffolding at B site."
      }), "\n", _jsx(_components.li, {
        children: "Boosted light emitted from candles/lanterns."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 15, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed pixel gap through scaffolding near B"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 21, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Increased size of plantable area in both bombsites"
      }), "\n", _jsx(_components.li, {
        children: "Fixed bullet penetration on plywood surfaces"
      }), "\n", _jsx(_components.li, {
        children: "Further optimizations"
      }), "\n", _jsx(_components.li, {
        children: "Added 1v1 arenas"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "May 27, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Art-revision on bombsite B and surrounding area"
      }), "\n", _jsx(_components.li, {
        children: "Higher contrast base textures at B for better player reads"
      }), "\n", _jsx(_components.li, {
        children: "Fixed random missing micro-polygons in bomb B totem/sculpture"
      }), "\n", _jsx(_components.li, {
        children: "Recolored plywood throughout the map for better contrast"
      }), "\n", _jsx(_components.li, {
        children: "Straightened out broken-wall passage to bombsite B"
      }), "\n", _jsx(_components.li, {
        children: "Tweaked spawnpoint placement for both teams"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "May 13, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Implemented Cubby 2.0 in mid connector to A, to remove cheap shoulder angle towards site"
      }), "\n", _jsx(_components.li, {
        children: "Slimmed down the fancy rock, also in mid connector to A"
      }), "\n", _jsx(_components.li, {
        children: "Sparsed foliage and improved clipping on boost planter near A main"
      }), "\n", _jsx(_components.li, {
        children: "Quieted down soundscape birds"
      }), "\n", _jsx(_components.li, {
        children: "Removed clipping from top of bombsite sign in A site, allowing you to self-boost to planter by T main"
      }), "\n", _jsx(_components.li, {
        children: "Added vphysics clip to T spawn water so guns don’t fully submerge"
      }), "\n", _jsx(_components.li, {
        children: "So many clip brush tweaks"
      }), "\n", _jsx(_components.li, {
        children: "Blocked grenades from entering inaccessible building by T entrance to mid"
      }), "\n", _jsx(_components.li, {
        children: "Blocked pixelgaps through scaffolding near B site"
      }), "\n", _jsx(_components.li, {
        children: "Blocked pixelgaps through doorway trim in new CT path to A site"
      }), "\n", _jsx(_components.li, {
        children: "Simplified grenade collision on rooftops"
      }), "\n", _jsx(_components.li, {
        children: "General optimizations"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "May 6, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Updated minimap image"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "May 3, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "New route from CT spawn to A site"
      }), "\n", _jsx(_components.li, {
        children: "Widened T entrance to left side of mid"
      }), "\n", _jsx(_components.li, {
        children: "Opened up skylight in T tunnel to A site"
      }), "\n", _jsx(_components.li, {
        children: "Extended plantable bomb zone in B site"
      }), "\n", _jsx(_components.li, {
        children: "Opened up ledge in A site"
      }), "\n", _jsx(_components.li, {
        children: "Various minor bugfixes"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 7, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Further optimizations"
      }), "\n", _jsx(_components.li, {
        children: "Fixed some clipping bugs"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 9, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reduced soundscape volume."
      }), "\n", _jsx(_components.li, {
        children: "Tweaked ambient light levels in some locations."
      }), "\n", _jsx(_components.li, {
        children: "Added additional lights to dark corners."
      }), "\n", _jsx(_components.li, {
        children: "Removed metal pillars by backdoor entrance to B site."
      }), "\n", _jsx(_components.li, {
        children: "Scaffolding by B site can now be shot through."
      }), "\n", _jsx(_components.li, {
        children: "Blocked visibility between boxes on A site."
      }), "\n", _jsx(_components.li, {
        children: "Tweaked blending for better player reads in some locations."
      }), "\n", _jsx(_components.li, {
        children: "Redid visual language of ledges around mid, more obvious what is/isnt pathable."
      }), "\n", _jsx(_components.li, {
        children: "Marked additional props for low end CPU/GPU culling."
      }), "\n", _jsx(_components.li, {
        children: "Mossy walls no longer surface type ‘grass’."
      }), "\n", _jsx(_components.li, {
        children: "Fixed invisible pottery."
      }), "\n", _jsx(_components.li, {
        children: "Removed some visual clutter around backdoor to B."
      }), "\n", _jsx(_components.li, {
        children: "General optimizations."
      }), "\n", _jsx(_components.li, {
        children: "General clipping fixes."
      }), "\n", _jsx(_components.li, {
        children: "Added de_ancient_zoo VMF to SDK for community map makers."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 3, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Map added to the game with the Operation Broken Fang update."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
