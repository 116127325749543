import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "June 15, 2022"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Another attempt at fixing molly through floor bug at mid."
      }), "\n", _jsx(_components.li, {
        children: "Removed a position by ramp corner."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 1, 2022"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed molotov/incendiary grenades going through the wall and floor in mid."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "April 23, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed gap under box on B site."
      }), "\n", _jsx(_components.li, {
        children: "Added ramp-clipping to side of box."
      }), "\n", _jsx(_components.li, {
        children: "Fixed some minor graphical bugs."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "April 16, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Brightened i-beam textures"
      }), "\n", _jsx(_components.li, {
        children: "Replaced wooden fence on A site with metal version, decreasing wallbang damage"
      }), "\n", _jsxs(_components.li, {
        children: ["Bombsite B:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsx(_components.li, {
            children: "Lowered height of site by 32 units"
          }), "\n", _jsx(_components.li, {
            children: "Tightened exit from T stairs"
          }), "\n", _jsx(_components.li, {
            children: "Removed “window” entrance from mid, replaced with a raised entrance near CT spawn"
          }), "\n", _jsx(_components.li, {
            children: "Flipped railings on stairs to site, to allow jump up from sandbag position"
          }), "\n", _jsx(_components.li, {
            children: "Reworked site layout and cover"
          }), "\n", _jsx(_components.li, {
            children: "Moved connector from B halls that went under mid, to further towards T spawn"
          }), "\n", _jsx(_components.li, {
            children: "Enabled B site in Wingman mode"
          }), "\n", _jsx(_components.li, {
            children: "Experiment: In Wingman mode, during Warmup, players will spawn in an 1v1 arena"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "November 22, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Updated radar for Vertigo."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "November 18, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added ramp to final stage of scaffolding on A ramp, no longer need to jump up"
      }), "\n", _jsx(_components.li, {
        children: "Connected corridor to back of A with CT elevator room"
      }), "\n", _jsx(_components.li, {
        children: "Widened top of mid"
      }), "\n", _jsx(_components.li, {
        children: "Offset doors from CT spawn and to CT elevator room"
      }), "\n", _jsx(_components.li, {
        children: "Made A site CT death funnel shorter, widened site area"
      }), "\n", _jsx(_components.li, {
        children: "Removed big block in CT spawn"
      }), "\n", _jsx(_components.li, {
        children: "Moved A ramp sandbags to allow jump up"
      }), "\n", _jsx(_components.li, {
        children: "Various bug and clipping fixes"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "April 02, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Replaced red fence section at top of A ramp (Thanks SPUNJ!)"
      }), "\n", _jsx(_components.li, {
        children: "Fixed pixel gaps on bombsite boxes"
      }), "\n", _jsx(_components.li, {
        children: "Raised floor of B stairs pit by 64 units"
      }), "\n", _jsx(_components.li, {
        children: "Reduced wallbang damage from CT elevator room towards T entrance to mid"
      }), "\n", _jsx(_components.li, {
        children: "Increased height of crane concrete base on A site"
      }), "\n", _jsx(_components.li, {
        children: "Improved visibility from B site towards T stairs"
      }), "\n", _jsx(_components.li, {
        children: "Added cover on right side when exiting stairs to B/exiting window"
      }), "\n", _jsx(_components.li, {
        children: "Fixed pixel gap from T spawn towards B catwalk"
      }), "\n", _jsx(_components.li, {
        children: "Fixed slight gaps between tarp sections on scaffolding"
      }), "\n", _jsx(_components.li, {
        children: "Fixed unintended boost on scaffolding at A ramp"
      }), "\n", _jsx(_components.li, {
        children: "Blocked weapons from being dropped underneath A ramp"
      }), "\n", _jsx(_components.li, {
        children: "Blocked weapons from being dropped underneath A site crane"
      }), "\n", _jsx(_components.li, {
        children: "Blocked players from going on the outside of ibeams in T spawn"
      }), "\n", _jsx(_components.li, {
        children: "Simplified collision on top of scaffolding models"
      }), "\n", _jsx(_components.li, {
        children: "Fixed various clipping bugs (thanks bonna97!)"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 28, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added new cover in bottom of mid, that doubles as a ramp towards “window” to B site"
      }), "\n", _jsx(_components.li, {
        children: "Pushed up CT spawns slightly"
      }), "\n", _jsx(_components.li, {
        children: "Prioritized spawn positions, CTs will be biased towards forward spawns, Ts biased towards rear spawns"
      }), "\n", _jsx(_components.li, {
        children: "Added corner railing to catwalk on B site"
      }), "\n", _jsx(_components.li, {
        children: "Blocked two-man boost on top of spools in CT spawn"
      }), "\n", _jsx(_components.li, {
        children: "Blocked angle over wall when going down stairs from B site towards T spawn"
      }), "\n", _jsx(_components.li, {
        children: "Tweaked T stairs up to bombsite B"
      }), "\n", _jsx(_components.li, {
        children: "Tweaked layout of T spawn slightly"
      }), "\n", _jsx(_components.li, {
        children: "Updated bombsite target models on sites"
      }), "\n", _jsx(_components.li, {
        children: "Elevator shaft injuries now count towards the injury statistic"
      }), "\n", _jsx(_components.li, {
        children: "Fixed various clipping issues"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 21, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Switched Wingman version to bombsite B"
      }), "\n", _jsx(_components.li, {
        children: "Made white metal structures on B site slightly larger, to prevent excessive angles/boost positions"
      }), "\n", _jsx(_components.li, {
        children: "Added some floor material variation for footstep differentiation (CT corridor to back of A, elevator room near A site, T entrance to mid)"
      }), "\n", _jsx(_components.li, {
        children: "Lowered C4 explosion radius (from 500 to 400)"
      }), "\n", _jsx(_components.li, {
        children: "Added grenade clip to roof props in forklift room/lower level that allowed molotovs to burn through to upper level"
      }), "\n", _jsx(_components.li, {
        children: "Extended catwalk by top of T stairs to B"
      }), "\n", _jsx(_components.li, {
        children: "Added more railings near A ramp"
      }), "\n", _jsx(_components.li, {
        children: "Fixed various clipping issues and C4 stuck spots (Thanks bonna97!)"
      }), "\n", _jsx(_components.li, {
        children: "Blocked weapons from falling into gap at bottom of A ramp (Thanks 3kliksphilip!)"
      }), "\n", _jsx(_components.li, {
        children: "Boosted ambient light in elevator room near A site"
      }), "\n", _jsx(_components.li, {
        children: "Removed a unintended boost on HVAC fan in B site"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 19, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Vertigo has been added to Competitive matchmaking"
      }), "\n", _jsx(_components.li, {
        children: "Changed CT entrance to mid, so that CTs have a safer rotation between sites"
      }), "\n", _jsx(_components.li, {
        children: "Moved forward plywood stack and wooden crate near T side of mid to make T boosts slightly safer"
      }), "\n", _jsx(_components.li, {
        children: "Smoothed out player collision on metal stairs in T spawn and B site"
      }), "\n", _jsx(_components.li, {
        children: "Updated soundscapes"
      }), "\n", _jsx(_components.li, {
        children: "Moved generator in T spawn up to second floor"
      }), "\n", _jsx(_components.li, {
        children: "Made it slightly easier to throw grenades out of ceiling in T corridor to mid"
      }), "\n", _jsx(_components.li, {
        children: "Minor tweaks and fixes"
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
