import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {appURLs} from "@/app/constants.mjs";
function _createMdxContent(props) {
  const _components = {
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Ancient is set within an archaeological excavation site nestled in what appears to be a Central American rainforest. This map is rich in various artifacts, including pots, artworks, and monuments that bear a striking resemblance to relics from the Aztec Empire."
    }), "\n", _jsx(_components.p, {
      children: "Within Ancient, the two main objectives, or bombsites, are characterized by unique themes and referred to as 'plazas' according to the in-game signage. Bombsite A is adorned with an orange motif, accentuated by tarps, paint, and signs that create a vivid atmosphere. In contrast, Bombsite B exhibits a pale blue color scheme, setting it apart from the other areas on the map. The distinct coloration and design elements of these bombsites contribute to the map's overall aesthetic and strategic gameplay."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite A:"
      }), " Featuring an orange motif, Bombsite A in Ancient is adorned with tarps, paint, and signs that create a vibrant ambiance. The site is filled with Aztec-inspired artifacts and structures, offering various hiding spots and tactical options. Distinct features like ramps and ledges add to the site's complexity, and its open layout provides opportunities for both long-range and close-quarters combat."]
    }), "\n", _jsx("div", {
      className: "images",
      children: _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/ancient_a_site.webp`
      })
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite B:"
      }), " Characterized by its pale blue color scheme, Bombsite B is set within an archaeological site filled with ancient monuments and relics. Its multi-layered design and variety of entry points create a unique tactical environment. The blend of open spaces and enclosed areas, along with the thematic elements such as pots and artworks, contribute to the site's immersive and strategic gameplay."]
    }), "\n", _jsxs("div", {
      className: "images",
      children: [_jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/ancient_b_site.webp`
      }), _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/ancient_b_site_2.webp`
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
