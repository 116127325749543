import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "May 3, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed a clipping issue on window in T lobby"
      }), "\n", _jsx(_components.li, {
        children: "Centered a lamp that was bugging me"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "November 22, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Brightened up corner by far vent in A site."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 9, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fix for VPhysics Displacement bug"
      }), "\n", _jsx(_components.li, {
        children: "Removed invisible collision that could block shots between ramp and toxic on Bombsite B"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "August 19, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Recompiled Nuke to fix several lighting problems on static props."
      }), "\n", _jsx(_components.li, {
        children: "April 2, 2019"
      }), "\n", _jsx(_components.li, {
        children: "Fixed C4 stuck spot outside near silo"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 3, 2018"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Made corridor leading to ramp room from T side slightly wider"
      }), "\n", _jsx(_components.li, {
        children: "Moved doors connected to trophy room slightly"
      }), "\n", _jsx(_components.li, {
        children: "Changed CT team to FBI"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 17, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Nuke has returned (available in Matchmaking included in the Operation Wildfire map group). For more details, visit: ", _jsx(_components.a, {
          href: "http://counter-strike.net/reintroducing_nuke",
          children: "http://counter-strike.net/reintroducing_nuke"
        })]
      }), "\n", _jsx(_components.li, {
        children: "Updated the Nuke loading screen icon."
      }), "\n", _jsx(_components.li, {
        children: "Now available in Competitive Matchmaking, and other game modes in the Operation Wildfire Map Group"
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
