import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 15, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Released Canals, available in all game modes."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 17, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed visible nodraw below CT bridge."
      }), "\n", _jsx(_components.li, {
        children: "Fixed several places where the bomb could get stuck if thrown."
      }), "\n", _jsx(_components.li, {
        children: "Fixed pixelwalk and skywalk exploits."
      }), "\n", _jsx(_components.li, {
        children: "Fixed sticky DM spawn point in tunnels."
      }), "\n", _jsx(_components.li, {
        children: "Fixed planting bomb on windowsill at B."
      }), "\n", _jsx(_components.li, {
        children: "Fixed missing collision on toolbox at back of B."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 20, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed more pixelwalk exploits."
      }), "\n", _jsx(_components.li, {
        children: "Fixed places where a dropped/thrown bomb could get stuck."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 24, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed more pixelwalk spots & places where bomb could get stuck."
      }), "\n", _jsx(_components.li, {
        children: "Fixed sticky clip brush at B."
      }), "\n", _jsx(_components.li, {
        children: "Fixed Z-fighting railing at bridge."
      }), "\n", _jsx(_components.li, {
        children: "Fixed visible nodraw underneath a bridge."
      }), "\n", _jsx(_components.li, {
        children: "Improved clipping under T side bridges."
      }), "\n", _jsx(_components.li, {
        children: "Fixed Z-fighting below CT arches."
      }), "\n", _jsx(_components.li, {
        children: "Adjusted courtyard statues and stairs for better movement."
      }), "\n", _jsx(_components.li, {
        children: "Fixed various fade distance issues."
      }), "\n", _jsx(_components.li, {
        children: "Widened B site and palace doors."
      }), "\n", _jsx(_components.li, {
        children: "Aligned palace windows better."
      }), "\n", _jsx(_components.li, {
        children: "Fixed floating clothesline."
      }), "\n", _jsx(_components.li, {
        children: "Adjusted pecklesteiner."
      }), "\n", _jsx(_components.li, {
        children: "Fixed missing texture at back alley."
      }), "\n", _jsx(_components.li, {
        children: "Removed clip from garden lamp post because it’s at jumping height."
      }), "\n", _jsx(_components.li, {
        children: "Prevented planting bomb on railing at A."
      }), "\n", _jsx(_components.li, {
        children: "Removed lamp posts between A and CT spawn."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 29, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed gap in bomb site B wall."
      }), "\n", _jsx(_components.li, {
        children: "Fixed missing texture in bridge visible from arch."
      }), "\n", _jsx(_components.li, {
        children: "Fixed overlapping geometry at A site."
      }), "\n", _jsx(_components.li, {
        children: "Fixed a few places near T spawn where bomb could get stuck."
      }), "\n", _jsx(_components.li, {
        children: "Increased fade distances on some props that were distracting."
      }), "\n", _jsx(_components.li, {
        children: "Adjusted wall geometry in prison stairs to fix lighting glitch."
      }), "\n", _jsx(_components.li, {
        children: "Increased lightmap grid resolution in prison lobby."
      }), "\n", _jsx(_components.li, {
        children: "Added a piece of cardboard under new toolbox at B to block glitchy sightline."
      }), "\n", _jsx(_components.li, {
        children: "Removed one of the picnic tables at Short."
      }), "\n", _jsx(_components.li, {
        children: "Fixed pixelwalk in shoe shop."
      }), "\n", _jsx(_components.li, {
        children: "Fixed errant clip brush on balcony."
      }), "\n", _jsx(_components.li, {
        children: "Made it more difficult to climb the wood sign to B from boost."
      }), "\n", _jsx(_components.li, {
        children: "Trimmed top corners of courtyard building facing the stairs."
      }), "\n", _jsx(_components.li, {
        children: "Fixed nav. file where lamp posts were removed."
      }), "\n", _jsx(_components.li, {
        children: "Adjusted balcony arch prop to fix gaps."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "April 20, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Rebuilt tunnel stairs area with smoother movement."
      }), "\n", _jsx(_components.li, {
        children: "Fixed boat geometry overlapping a pier at CT spawn."
      }), "\n", _jsx(_components.li, {
        children: "Fixed box intersecting windowsill at B."
      }), "\n", _jsx(_components.li, {
        children: "Fixed pixelwalk on railing at CT spawn."
      }), "\n", _jsx(_components.li, {
        children: "Moved plywood down on scaffolding below B to block a bad sightline."
      }), "\n", _jsx(_components.li, {
        children: "Fixed sticky scaffolding clip brush below B."
      }), "\n", _jsx(_components.li, {
        children: "Fixed some fade distance pop-up issues."
      }), "\n", _jsx(_components.li, {
        children: "Added two picnic chairs at short and made clips smoother."
      }), "\n", _jsx(_components.li, {
        children: "Removed lamp at ivy/garbage."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 8, 2018"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Closed off windows overlooking courtyard and A"
      }), "\n", _jsx(_components.li, {
        children: "Cleaned up sightline from corner balcony toward A and Arch"
      }), "\n", _jsx(_components.li, {
        children: "Made CT route to B more direct"
      }), "\n", _jsx(_components.li, {
        children: "Joined two rooms on CT side of B into one space"
      }), "\n", _jsx(_components.li, {
        children: "Simplified cover on back side of B"
      }), "\n", _jsx(_components.li, {
        children: "Removed Ivy “laddice”, now a skill jump / boost spot"
      }), "\n", _jsx(_components.li, {
        children: "Grenade-clipped CT bridge railings for more predictable smokes"
      }), "\n", _jsx(_components.li, {
        children: "Moved courtyard statues forward for cleaner sightline"
      }), "\n", _jsx(_components.li, {
        children: "Added ashtray for smokers"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 9, 2018"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Canals has been removed from Competitive Matchmaking."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
