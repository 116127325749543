import React from "react";
import { useTranslation } from "react-i18next";

import { HeaderIcon } from "@/game-cs2-marketing/CommonComponents.jsx";
import { MAPS } from "@/game-cs2-marketing/constants.mjs";
import DatabaseMapOverview from "@/game-cs2-marketing/DatabaseMapOverview";
import { getMapImage } from "@/game-cs2-marketing/utils.mjs";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader";
import { useRoute } from "@/util/router-hooks.mjs";

const TABS = [
  {
    key: "overview",
    text: "Overview",
    component: DatabaseMapOverview,
  },
];

function DatabaseMap() {
  const { t } = useTranslation();
  const {
    parameters: [mapParam, tab],
  } = useRoute();

  const [mapKey, map] =
    Object.entries(MAPS).find(([key]) => key === mapParam) || [];

  const links = TABS.map((tab) => {
    return {
      url: `/cs2/database/maps/${mapKey}/${tab.key}`,
      text: tab.text,
    };
  });
  const selectedTab = TABS.find((t) => t.key === tab);
  const Component = selectedTab?.component;

  if (!map) return null;

  return (
    <PageContainer>
      <PageHeader
        title={t(`cs2.maps.${map.i18nKey}`, map.name)}
        ImageComponent={<HeaderIcon src={getMapImage(mapKey)} />}
        links={links}
      />
      <Component map={mapParam} />
    </PageContainer>
  );
}

export default DatabaseMap;

export function meta([tab]) {
  const [, map] = Object.entries(MAPS).find(([key]) => key === tab) || [];
  return {
    title: [
      "cs2:meta.database.maps.title",
      "CS2 {{mapName}} Map",
      { mapName: map.name },
    ],
    description: [
      "cs2:meta.database.maps.description",
      "Discover the strategic depth of CS2 with our detailed map information",
    ],
    subtitle: false,
  };
}
