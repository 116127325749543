import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {appURLs} from "@/app/constants.mjs";
function _createMdxContent(props) {
  const _components = {
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Set against the rugged backdrop of a Southwestern American town, Tucson serves up a unique blend of traditional Western elements and modern tactical gameplay. From dilapidated saloons to dusty open-air markets, this map provides a rich tapestry of settings for firefights."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite A:"
      }), " Featuring a wild west theme with wooden facades and open courtyards, this bombsite allows for creative utility use and lends itself to both short and long-range engagements."]
    }), "\n", _jsxs("div", {
      className: "images",
      children: [_jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/tuscan_a_site.webp`
      }), _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/tuscan_a_site.webp`
      })]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite B:"
      }), " Enclosed by sturdy buildings and littered with crates and barrels, this interior site puts a premium on close-quarter combat and fast reflexes."]
    }), "\n", _jsxs("div", {
      className: "images",
      children: [_jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/tuscan_b_site.webp`
      }), _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/tuscan_b_site_2.webp`
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
