import type { ComponentType } from "react";

import AncientUpdates from "@/game-cs2-marketing/map-updates/de_ancient.mdx";
import AnubisUpdates from "@/game-cs2-marketing/map-updates/de_anubis.mdx";
import CacheUpdates from "@/game-cs2-marketing/map-updates/de_cache.mdx";
import CanalsUpdates from "@/game-cs2-marketing/map-updates/de_canals.mdx";
import CobblestoneUpdates from "@/game-cs2-marketing/map-updates/de_cbble.mdx";
import Dust2Updates from "@/game-cs2-marketing/map-updates/de_dust2.mdx";
import InfernoUpdates from "@/game-cs2-marketing/map-updates/de_inferno.mdx";
import MirageUpdates from "@/game-cs2-marketing/map-updates/de_mirage.mdx";
import NukeUpdates from "@/game-cs2-marketing/map-updates/de_nuke.mdx";
import OverpassUpdates from "@/game-cs2-marketing/map-updates/de_overpass.mdx";
import TrainUpdates from "@/game-cs2-marketing/map-updates/de_train.mdx";
import TuscanUpdates from "@/game-cs2-marketing/map-updates/de_tuscan.mdx";
import VertigoUpdates from "@/game-cs2-marketing/map-updates/de_vertigo.mdx";

type Updates = {
  [key: string]: ComponentType;
};

const MAP_UPDATES: Updates = {
  de_mirage: MirageUpdates,
  de_dust2: Dust2Updates,
  de_inferno: InfernoUpdates,
  de_overpass: OverpassUpdates,
  de_nuke: NukeUpdates,
  de_ancient: AncientUpdates,
  de_anubis: AnubisUpdates,
  de_vertigo: VertigoUpdates,
  de_cache: CacheUpdates,
  de_cbble: CobblestoneUpdates,
  de_train: TrainUpdates,
  de_tuscan: TuscanUpdates,
  de_canals: CanalsUpdates,
};

export default MAP_UPDATES;
