import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";
import { ToggleSwitch } from "clutch/src/ToggleSwitch/ToggleSwitch.jsx";

import BlitzAdd from "@/inline-assets/add.svg";
import BlitzVisibility from "@/inline-assets/blitz-visibility.svg";
import BlitzClose from "@/inline-assets/close.svg";
import BlitzRemove from "@/inline-assets/remove.svg";

const MapControls = styled("div")`
  position: absolute;
  right: var(--sp-10);
  bottom: var(--sp-10);
  z-index: 3;
  user-select: none;

  ${mobile} {
    bottom: unset;
    right: var(--sp-1);
    top: var(--sp-1);
  }

  .control-btn {
    padding: 0.25rem;
    height: var(--sp-8);
    width: var(--sp-8);
    color: var(--shade2);
    background: var(--shade7);
    border-radius: var(--br);
    box-shadow:
      0 2px 2px rgba(0, 0, 0, 0.1),
      0 7px 8px rgba(0, 0, 0, 0.2),
      0 30px 32px rgba(0, 0, 0, 0.3);
    transition: var(--transition);
    cursor: pointer;

    &:hover {
      color: var(--shade0);
      background: var(--shade6);
    }
  }
`;

const ViewSettings = styled("div")`
  position: relative;

  .control-btn {
    margin-bottom: var(--sp-2);
  }
  .view-options-menu {
    position: absolute;
    bottom: 0;
    right: var(--sp-10);
    padding: var(--sp-2);
    border-radius: var(--br);
    background: var(--shade10);
    box-shadow:
      0 3px 3px rgba(0, 0, 0, 0.1),
      0 10px 10px rgba(0, 0, 0, 0.1),
      0 40px 40px rgba(0, 0, 0, 0.2);

    ${mobile} {
      bottom: unset;
      top: 0;
    }
  }
  .view-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    height: var(--sp-12);
    padding: 0 var(--sp-3);
    border-radius: var(--br);
    cursor: pointer;

    .title {
      padding-right: calc(var(--sp-1) * 15);
    }

    &:not(:last-child) {
      margin-bottom: var(--sp-0_5);
    }
  }
`;

const Zoom = styled("div")`
  ${mobile} {
    display: none;
  }
  & > .control-btn:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  & > .control-btn:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

const MapZoom = ({
  viewSettings,
  setViewSettings,
  zoomIn,
  zoomOut,
  options,
}) => {
  const [controlsOpen, setControlsOpen] = useState(false);

  const handleToggle = useCallback(
    (key) => {
      setViewSettings({
        ...viewSettings,
        [key]: !viewSettings[key],
      });
    },
    [setViewSettings, viewSettings],
  );

  const { t } = useTranslation();

  return (
    <MapControls>
      <ViewSettings>
        {options.length > 0 && (
          <div
            className="control-btn"
            onClick={() =>
              setControlsOpen((setControlsOpen) => !setControlsOpen)
            }
          >
            {controlsOpen ? <BlitzClose /> : <BlitzVisibility />}
          </div>
        )}
        {controlsOpen && (
          <div className="view-options-menu">
            {options.map((option) => {
              return (
                <div
                  key={option.key}
                  className="view-option"
                  onClick={() => handleToggle(option.key)}
                >
                  <div className="title">
                    <span className="type-body2">{t(...option.label)}</span>
                  </div>
                  <ToggleSwitch value={viewSettings[option.key]} />
                </div>
              );
            })}
          </div>
        )}
      </ViewSettings>

      <Zoom>
        <div className="control-btn" onClick={() => zoomIn()}>
          <BlitzAdd />
        </div>
        <div className="control-btn" onClick={() => zoomOut()}>
          <BlitzRemove />
        </div>
      </Zoom>
    </MapControls>
  );
};

export default MapZoom;
