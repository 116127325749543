import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {appURLs} from "@/app/constants.mjs";
function _createMdxContent(props) {
  const _components = {
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Depicting a rustic European village, Inferno provides a complex battlefield filled with narrow pathways, hidden corners, and strategic choke points. Bombsite A is known for its infamous truck and balcony, while Bombsite B is surrounded by a network of tight alleys. The map encourages team coordination and strategy, with a rich visual design inspired by the Italian countryside."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite A:"
      }), " Surrounded by the arch, truck, and balcony, Bombsite A offers multiple entry points, creating complex defensive and offensive strategies. The blend of open space and cover challenges players to carefully navigate the site."]
    }), "\n", _jsxs("div", {
      className: "images",
      children: [_jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/inferno_a_site.webp`
      }), _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/inferno_a_site_2.webp`
      })]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite B:"
      }), " Encased by stone walls and known for its famous \"banana\" approach, Bombsite B favors tactical movement and positioning. The fountain and sandbags offer unique cover spots."]
    }), "\n", _jsxs("div", {
      className: "images",
      children: [_jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/inferno_b_site.webp`
      }), _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/inferno_b_site2.webp`
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
