import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "June 15, 2022"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Increased bomb explosion damage from 500 to 620."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 21, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Made gate at top of banana solid"
      }), "\n", _jsx(_components.li, {
        children: "Revised 1v1 layout"
      }), "\n", _jsx(_components.li, {
        children: "Minor bug fixes"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "November 22, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Improved visibility from patio towards pit boost spot."
      }), "\n", _jsx(_components.li, {
        children: "Added fill lights in A site balcony hallway."
      }), "\n", _jsx(_components.li, {
        children: "Fixed a DM spawn in apartments that caused players to get stuck."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "April 25, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added nav blockers for Wingman mode"
      }), "\n", _jsx(_components.li, {
        children: "Fixed a bug where fountain particle effects would sometimes not play"
      }), "\n", _jsx(_components.li, {
        children: "Brightened up gate-area at top of banana"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "August 1, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed some minor graphical bugs on Inferno."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 3, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Optimized water materials used in Inferno, Nuke and Aztec maps."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 12, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed some minor graphical bugs"
      }), "\n", _jsx(_components.li, {
        children: "Fixed a spot where players could get stuck near Boiler, Bombsite A"
      }), "\n", _jsx(_components.li, {
        children: "Added backfaces to gate used at bottom of Banana, Bombsite B"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 5, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed a bug where players could get stuck in ceilingfans."
      }), "\n", _jsx(_components.li, {
        children: "v*Fixed some areas where thrown C4 could get stuck."
      }), "\n", _jsx(_components.li, {
        children: "Increased brightness slightly in hallway leading to balcony in Bombsite A."
      }), "\n", _jsx(_components.li, {
        children: "Fixed gaps that players could see through in Bombsite B."
      }), "\n", _jsx(_components.li, {
        children: "Made wall penetration in construction more consistent (Thanks Pawlesslol!)."
      }), "\n", _jsx(_components.li, {
        children: "Nerfed the refire rate on the churchbells (changed from infinite dings per second to 1 ding per second)."
      }), "\n", _jsx(_components.li, {
        children: "General optimizations."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
