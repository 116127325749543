import React from "react";
import { styled } from "goober";
import {
  TransformComponent,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch";

import { getMapLayout } from "@/game-cs2-marketing/utils.mjs";
import MapControls from "@/shared-fps/MapControls.jsx";

const OPTIONS = {
  defaultScale: 1,
  limitToBounds: false,
  maxScale: 5,
  minScale: 0.5,
  wheel: {
    step: 0.5,
  },
};

const SVGMap = styled("div")`
  max-height: calc(var(--sp-10) * 10);
  &.full {
    max-height: initial;
    height: 100%;
    width: 100%;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

const MAP_VIEW_OPTIONS = [];

function MapLayout({ map, full = false }) {
  return (
    <TransformWrapper {...OPTIONS}>
      {({ zoomIn, zoomOut }) => (
        <>
          <TransformComponent>
            <SVGMap className={full ? "full" : ""}>
              <img src={getMapLayout(map)} alt="map layout" />
            </SVGMap>
          </TransformComponent>
          <MapControls
            viewSettings={{}}
            setViewSettings={() => {}}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            options={MAP_VIEW_OPTIONS}
          />
        </>
      )}
    </TransformWrapper>
  );
}

export default MapLayout;
