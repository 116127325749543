import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {appURLs} from "@/app/constants.mjs";
function _createMdxContent(props) {
  const _components = {
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Train is set in an industrial train yard filled with boxcars and locomotives. Its wide-open bombsites, located near and under train cars, create unique opportunities for both sniping and close-quarters combat. The juxtaposition of the interiors of train cars with the exteriors provides a multi-dimensional playing field that encourages creative strategies."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite A:"
      }), " Set among train cars, Bombsite A offers long sightlines and hiding spots between the carriages. The juxtaposition of open space and tight interiors creates a dynamic play area."]
    }), "\n", _jsxs("div", {
      className: "images",
      children: [_jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/train_a_site.webp`
      }), _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/train_a_site.webp`
      })]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite B:"
      }), " Known for its lower and upper ramp, Bombsite B is also surrounded by trains, providing a blend of cover and open combat zones. The oil train and spools add to the site's complexity."]
    }), "\n", _jsxs("div", {
      className: "images",
      children: [_jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/train_b_site.webp`
      }), _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/train_b_site_2.webp`
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
