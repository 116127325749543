import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 29, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Opened up skybox over building separating Ivy/A site"
      }), "\n", _jsx(_components.li, {
        children: "Various visibility improvements"
      }), "\n", _jsx(_components.li, {
        children: "Various minor bug fixes"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "April 25, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added nav blocker entities for Wingman"
      }), "\n", _jsx(_components.li, {
        children: "Changed ground material to a brighter version"
      }), "\n", _jsx(_components.li, {
        children: "Updated train car models with improved collision model"
      }), "\n", _jsx(_components.li, {
        children: "Added white background near T entrance to A main"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 31, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added to the Active Duty map group."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 18, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Widened lower ladder room."
      }), "\n", _jsx(_components.li, {
        children: "Blocked visibility from Bombsite B lower ramp to T ramp."
      }), "\n", _jsx(_components.li, {
        children: "Pushed back T spawn slightly."
      }), "\n", _jsx(_components.li, {
        children: "Updated collision model on train bumper models."
      }), "\n", _jsx(_components.li, {
        children: "Fixed some spots where C4 could get stuck."
      }), "\n", _jsx(_components.li, {
        children: "Fixed sun orientation."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 11, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Sadly fixed a hilarious bug where players could ride pigeons"
      }), "\n", _jsx(_components.li, {
        children: "Fixed some spots where a dropped bomb could get stuck"
      }), "\n", _jsx(_components.li, {
        children: "Fixed player collision on Bombsite A white boxes"
      }), "\n", _jsx(_components.li, {
        children: "Fixed one-way wallbang through shack near connector"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 10, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reintroduced Train, which has been added to the Operation Vanguard map group (available for free to all CS:GO players)."
      }), "\n", _jsxs(_components.li, {
        children: ["For more information, visit ", _jsx(_components.a, {
          href: "http://www.counter-strike.net/reintroducing_train",
          children: "www.counter-strike.net/reintroducing_train"
        })]
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
