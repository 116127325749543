import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 23, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added collision to top of trees"
      }), "\n", _jsx(_components.li, {
        children: "Added more accurate model collision"
      }), "\n", _jsx(_components.li, {
        children: "Removed particle effects that could be mistaken for grenades"
      }), "\n", _jsx(_components.li, {
        children: "Blocked a sightline in battlements near A"
      }), "\n", _jsx(_components.li, {
        children: "Blocked a hiding hole in underpass near A"
      }), "\n", _jsx(_components.li, {
        children: "Removed light models near statue at B that could be mistaken for player heads"
      }), "\n", _jsx(_components.li, {
        children: "Removed a portapotty at back of B"
      }), "\n", _jsx(_components.li, {
        children: "Raised height of center fence near B"
      }), "\n", _jsx(_components.li, {
        children: "Fixed various minor bugs"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "May 28, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Blocked long sightline from back of Bombsite B"
      }), "\n", _jsx(_components.li, {
        children: "No longer possible to plant on top of hut at bombsite A"
      }), "\n", _jsx(_components.li, {
        children: "Made statue base at bombsite B taller, so players can't peek over"
      }), "\n", _jsx(_components.li, {
        children: "Made hut on bombsite B catwalk wider"
      }), "\n", _jsx(_components.li, {
        children: "Moved all T spawns to upper level"
      }), "\n", _jsx(_components.li, {
        children: "Decreased fog"
      }), "\n", _jsx(_components.li, {
        children: "Removed small tree near platform in mid"
      }), "\n", _jsx(_components.li, {
        children: "Pushed down some vegetation that players could hide in"
      }), "\n", _jsx(_components.li, {
        children: "Disabled collision on small rocks"
      }), "\n", _jsx(_components.li, {
        children: "Blocked visibility through cart in mid"
      }), "\n", _jsx(_components.li, {
        children: "Revised cover in Bombsite B courtyard"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 1, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added to the Active Duty map group"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 16, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Removed OP flower pot at back of B."
      }), "\n", _jsx(_components.li, {
        children: "Added a lip at the top of main B entrance to provide more cover to T team."
      }), "\n", _jsx(_components.li, {
        children: "Slightly extended large planter by B entrance to close a thin sight line from back of B to side door."
      }), "\n", _jsx(_components.li, {
        children: "Drop-down to alternative B entrance has been widened to allow a better view of the room below."
      }), "\n", _jsx(_components.li, {
        children: "Drop-down room roof beams have been moved further away from the stairs."
      }), "\n", _jsx(_components.li, {
        children: "Roof of alternative B entrance has been opened up for additional grenade throws."
      }), "\n", _jsx(_components.li, {
        children: "Locations where the bomb could be dropped out of players’ reach have been clipped off."
      }), "\n", _jsx(_components.li, {
        children: "Lighting changes at some locations to provide better visibility."
      }), "\n", _jsx(_components.li, {
        children: "Shifted tapestry in CT sniper room up and made it lighter color to make players more visible against it."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "August 1, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Changed terrorist faction to Phoenix"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 2, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added T spawns to lower position, prioritized them over battlement spawns"
      }), "\n", _jsx(_components.li, {
        children: "Added ladder from bottom of T spawn to battlement near T sniper tower"
      }), "\n", _jsx(_components.li, {
        children: "Neutralized corner when moving through archhouse on B upper catwalk"
      }), "\n", _jsx(_components.li, {
        children: "Neutralized corner when entering B site through connector"
      }), "\n", _jsx(_components.li, {
        children: "Moved back CT spawns"
      }), "\n", _jsx(_components.li, {
        children: "Tightened entrance corridor to Long A, T side"
      }), "\n", _jsx(_components.li, {
        children: "Reduced depth of CT sniper room and made windows more open"
      }), "\n", _jsx(_components.li, {
        children: "Reconfigured B tunnels for faster T rotation time"
      }), "\n", _jsx(_components.li, {
        children: "Added path from T side of underpass into B tunnels"
      }), "\n", _jsx(_components.li, {
        children: "Reduced sightlines from back of B to entrances"
      }), "\n", _jsx(_components.li, {
        children: "Lowered railing at back of B to make characters on platform easier to spot"
      }), "\n", _jsx(_components.li, {
        children: "Cut out area behind the toilet in back of B"
      }), "\n", _jsx(_components.li, {
        children: "Minor lighting/texturing changes at A for better visibility"
      }), "\n", _jsx(_components.li, {
        children: "Rotated window overlooking connector"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 14, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed sticky spot under roof overhang at B"
      }), "\n", _jsx(_components.li, {
        children: "Fixed overview map not matching the geometry at Gallery"
      }), "\n", _jsx(_components.li, {
        children: "Raised ceiling and added trim in Gallery"
      }), "\n", _jsx(_components.li, {
        children: "Fixed sticky clip brush at A"
      }), "\n", _jsx(_components.li, {
        children: "Added clip brush to tapestry in CT sniper room so you can’t walk through it"
      }), "\n", _jsx(_components.li, {
        children: "Fixed floating electrical box in sewer stairwell"
      }), "\n", _jsx(_components.li, {
        children: "Fixed missing texture above T ramp"
      }), "\n", _jsx(_components.li, {
        children: "Removed handrail/clips and adjusted lighting in CT sniper hallway"
      }), "\n", _jsx(_components.li, {
        children: "Fixed wooden platform clipping into stone in T spawn"
      }), "\n", _jsx(_components.li, {
        children: "Made window bars in B tunnels & Gallery non-solid"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 4, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Major changes to Cobblestone"
      }), "\n", _jsxs(_components.li, {
        children: ["Bombsite A:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsx(_components.li, {
            children: "Made bombsite A courtyard smaller"
          }), "\n", _jsx(_components.li, {
            children: "Removed spiral stairs at back of bombsite A"
          }), "\n", _jsx(_components.li, {
            children: "Pushed forward side-balcony at bombsite A, making it easier to check corners before jumping down"
          }), "\n", _jsx(_components.li, {
            children: "Added a static door to entrance to connector from bombsite A"
          }), "\n", _jsx(_components.li, {
            children: "Shortened battlement in long A, making it easier to check"
          }), "\n", _jsx(_components.li, {
            children: "Raised wall around bombsite A slightly, to give some cover when out in the open"
          }), "\n", _jsx(_components.li, {
            children: "Added additional cover in bombsite A"
          }), "\n", _jsx(_components.li, {
            children: "Removed balcony at CT side of long A"
          }), "\n", _jsx(_components.li, {
            children: "Added an additional chest-height wall at A ramp"
          }), "\n", _jsx(_components.li, {
            children: "Removed large boulders in A site"
          }), "\n"]
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: ["Bombsite B:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsx(_components.li, {
            children: "Made bombsite B courtyard smaller"
          }), "\n", _jsx(_components.li, {
            children: "Rerouted T main entrance to bombsite B"
          }), "\n", _jsx(_components.li, {
            children: "Made it easier to throw grenades into B site from a safe position as T"
          }), "\n", _jsx(_components.li, {
            children: "Added some cover on T platform in bombsite B"
          }), "\n", _jsx(_components.li, {
            children: "Moved bombsite B more to the middle of the courtyard"
          }), "\n", _jsx(_components.li, {
            children: "Flattened ground at bombsite B"
          }), "\n", _jsx(_components.li, {
            children: "Removed back of B area"
          }), "\n", _jsx(_components.li, {
            children: "Hut at back of B can now be shot through"
          }), "\n", _jsx(_components.li, {
            children: "Added a static door to entrance to bombsite B from connector"
          }), "\n"]
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: ["Other:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsx(_components.li, {
            children: "Removed stairs and overpass route from dropdown room"
          }), "\n", _jsx(_components.li, {
            children: "Widened upper dropdown passage"
          }), "\n", _jsx(_components.li, {
            children: "Added a ramp to dropdown to make it easier to peek into lower"
          }), "\n", _jsx(_components.li, {
            children: "Players can now jump up via flowerbed in connector to get into dropdown room"
          }), "\n", _jsx(_components.li, {
            children: "Removed walls in T tunnels"
          }), "\n", _jsx(_components.li, {
            children: "Removed CT sniper room"
          }), "\n", _jsx(_components.li, {
            children: "Added a static door to CT side of water room"
          }), "\n", _jsx(_components.li, {
            children: "Tweaked layout of water room slightly"
          }), "\n", _jsx(_components.li, {
            children: "Removed double window room near T spawn"
          }), "\n", _jsx(_components.li, {
            children: "Moved T spawns forward a bit"
          }), "\n", _jsx(_components.li, {
            children: "Added a direct ladder to T sniper tower"
          }), "\n", _jsx(_components.li, {
            children: "Various optimizations"
          }), "\n", _jsx(_components.li, {
            children: "Increased player visibility"
          }), "\n", _jsx(_components.li, {
            children: "Fixed a bug where very few chickens were spawned"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 12, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Removed various boosts"
      }), "\n", _jsx(_components.li, {
        children: "Further optimizations"
      }), "\n", _jsx(_components.li, {
        children: "Tweaked layout of underpass room"
      }), "\n", _jsx(_components.li, {
        children: "Moved door from CT side to T side of underpass"
      }), "\n", _jsx(_components.li, {
        children: "Made cubby at entrance to Bombsite A smaller"
      }), "\n", _jsx(_components.li, {
        children: "Widened arch at end of long A"
      }), "\n", _jsx(_components.li, {
        children: "Set correct surface types on textures"
      }), "\n", _jsx(_components.li, {
        children: "Brightened haystacks at entrance to Long A"
      }), "\n", _jsx(_components.li, {
        children: "Updated collision on broken wall in bombsite A and B"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 19, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed some exploits and unintended boosts."
      }), "\n", _jsx(_components.li, {
        children: "Further optimizations."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 31, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Made cubby near bottom of platform stairs in Bombsite B deeper"
      }), "\n", _jsx(_components.li, {
        children: "Smoothed out movement around B ramp and dropdown room"
      }), "\n", _jsx(_components.li, {
        children: "Fixed various spots where C4 could get stuck (Thanks Residents_evil!)"
      }), "\n", _jsx(_components.li, {
        children: "Fixed some surfaces playing the wrong footstep sounds"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 8, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed some areas where players could look through model backfaces"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 23, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Updated loading screen image"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 14, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Removed support beam in drop room which blocked line of sight to drop/door"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 15, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Raised arch on CT side of long A"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 23, 2018"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Removed connector between Long A and Underpass"
      }), "\n", _jsx(_components.li, {
        children: "Blocked long view angle from Long A CT side towards courtyard"
      }), "\n", _jsx(_components.li, {
        children: "Decreased height of doors in Underpass"
      }), "\n", _jsx(_components.li, {
        children: "Added window in Underpass"
      }), "\n", _jsx(_components.li, {
        children: "Removed slanted door leading into Underpass"
      }), "\n", _jsx(_components.li, {
        children: "Removed a large section of old Bombsite A courtyard"
      }), "\n", _jsx(_components.li, {
        children: "Moved up bombsite A zone towards ramp"
      }), "\n", _jsx(_components.li, {
        children: "Removed access to most of T spawn area"
      }), "\n", _jsx(_components.li, {
        children: "Moved up T spawn positions"
      }), "\n", _jsx(_components.li, {
        children: "Added climbable props in dropdown"
      }), "\n", _jsx(_components.li, {
        children: "Added cover in corridor leading to Bombsite B platform"
      }), "\n", _jsx(_components.li, {
        children: "Added spooky Halloween theme"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 19, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Cobblestone has been removed from Competitive Matchmaking."
      }), "\n", _jsx(_components.li, {
        children: "Cobblestone and Vertigo have been moved into Defusal Group Sigma"
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
