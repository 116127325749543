import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {appURLs} from "@/app/constants.mjs";
function _createMdxContent(props) {
  const _components = {
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Dust II, set in a North African desert town, is one of the most iconic maps in CS:GO. It features a contrast of tight tunnels and open spaces, providing diverse tactical opportunities. Bombsite A, with its signature \"Long A\" approach, offers long-range combat, while Bombsite B is accessible through narrower passages that favor close encounters. Its distinct sandy appearance and recognizable callouts like \"Catwalk\" make it a favorite among players of all levels."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite A:"
      }), " Dominated by its iconic \"Goose\" area and \"Long A\" corridor, Bombsite A provides opportunities for both long-range and close-quarters combat. Its open design, punctuated by crates and ramps, demands precision and coordination."]
    }), "\n", _jsxs("div", {
      className: "images",
      children: [_jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/dust2_a_site.webp`
      }), _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/dust2_a_site_2.webp`
      })]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite B:"
      }), " Enclosed within a stone structure, Bombsite B is accessible through tight tunnels, making it a hotspot for intense firefights. The window overlooking the site adds an additional tactical layer."]
    }), "\n", _jsxs("div", {
      className: "images",
      children: [_jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/dust2_b_site.webp`
      }), _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/dust2_b_site2.webp`
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
