import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "June 15, 2022"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed an exploit. (Thanks El_Exodus!)"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "May 27, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Performance optimizations. (Thanks leplubodeslapin!)"
      }), "\n", _jsx(_components.li, {
        children: "Reduced bird sounds volume."
      }), "\n", _jsx(_components.li, {
        children: "Reduced contrast on some textures."
      }), "\n", _jsx(_components.li, {
        children: "Brightened sky slightly."
      }), "\n", _jsx(_components.li, {
        children: "Fixed minor issues. (Thanks imik!)"
      }), "\n", _jsx(_components.li, {
        children: "Restored vivid color to select textures and models."
      }), "\n", _jsx(_components.li, {
        children: "Restored select details even on lowest graphical settings."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 7, 2021"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed a pixel-gap in the Cargo containers in T-Spawn (Thanks to the perceptive individual that reported it to 3kliksphilip!)"
      }), "\n", _jsx(_components.li, {
        children: "Fixed Cargo containers not having their proper colors."
      }), "\n", _jsx(_components.li, {
        children: "Fixed bomb explosions being visible above the A site. (Thanks markman2575!)"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 29, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Various visibility improvements"
      }), "\n", _jsx(_components.li, {
        children: "Optimizations"
      }), "\n", _jsx(_components.li, {
        children: "Various minor bug fixes"
      }), "\n", _jsx(_components.li, {
        children: "Updated radar"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "November 18, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Cache has been moved from Scrimmage to Competitive Matchmaking"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 18, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Cache has been updated to the latest version from the Steam Workshop and is now available in Casual, Deathmatch, and Scrimmage."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 6, 2018"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Updated the BSP embedded radar map image to the new version."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 20, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fix for vent break sound."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 12, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed sound-spam when shooting at vent in Checkers through a prop"
      }), "\n", _jsx(_components.li, {
        children: "Clipping improvements"
      }), "\n", _jsx(_components.li, {
        children: "Optimizations"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 25, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Minor bug fixes"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 15, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added graffiti to commemorate ESL One Cologne 2016."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "June 9, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Minor bug fixes"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "May 19, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Forklift"
      }), "\n", _jsx(_components.li, {
        children: "with"
      }), "\n", _jsx(_components.li, {
        children: "Roof"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "May 4, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Visibility improvements based on color-blind player feedback"
      }), "\n", _jsx(_components.li, {
        children: "Texture improvements"
      }), "\n", _jsx(_components.li, {
        children: "New vent models"
      }), "\n", _jsx(_components.li, {
        children: "Added subtle markers for grenade throws (thanks James Bardolph)"
      }), "\n", _jsx(_components.li, {
        children: "Added plant-zone decals to Bombsite B"
      }), "\n", _jsx(_components.li, {
        children: "Added graphic for “Sun-room” (thanks Thurnip)"
      }), "\n", _jsx(_components.li, {
        children: "Fixed fade distance on crate in checkers"
      }), "\n", _jsx(_components.li, {
        children: "Fixed weapons being irretrievable under vent"
      }), "\n", _jsx(_components.li, {
        children: "Fixed all known bomb-stuck spots"
      }), "\n", _jsx(_components.li, {
        children: "Fixed an exploit involving flashbangs from mid into checkers"
      }), "\n", _jsx(_components.li, {
        children: "Fixed pixelwalks at mid, T-Spawn, A main, A site"
      }), "\n", _jsx(_components.li, {
        children: "Improved .nav mesh (thanks p_NM)"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 26, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed one-way texture at Quad boxes"
      }), "\n", _jsx(_components.li, {
        children: "Improved visibility of vents"
      }), "\n", _jsx(_components.li, {
        children: "Fixed pixelwalk at B fences"
      }), "\n", _jsx(_components.li, {
        children: "Improved matching of foot-step sounds and ground materials across the map"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 19, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed various minor bugs"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "May 26, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed multiple bomb-stuck spots"
      }), "\n", _jsx(_components.li, {
        children: "Fixed an issue where saving CTs could be seen on radar from outside spawn"
      }), "\n", _jsx(_components.li, {
        children: "Fixed an annoying issue where grenades would collide with ground geometry map-wide"
      }), "\n", _jsx(_components.li, {
        children: "Deleted a problematic glass pane in mid (thanks RoF Anders)"
      }), "\n", _jsx(_components.li, {
        children: "Ensured tarps on crates are actually spammable"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 14, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Exploit fix in Bombsite B."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 23, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added solidity to gates in b halls and ct garage to allow for nade creativity."
      }), "\n", _jsx(_components.li, {
        children: "Improved readability in B heaven."
      }), "\n", _jsx(_components.li, {
        children: "Improved readability by quad."
      }), "\n", _jsx(_components.li, {
        children: "Improved lighting by forklift."
      }), "\n", _jsx(_components.li, {
        children: "Improved prop-consistency by quad by adding more rigid edges."
      }), "\n", _jsx(_components.li, {
        children: "Increased visibility in vent."
      }), "\n", _jsx(_components.li, {
        children: "Fixed missing texture issues."
      }), "\n", _jsx(_components.li, {
        children: "Fixed various lighting issues."
      }), "\n", _jsx(_components.li, {
        children: "Fixed texture translation issues."
      }), "\n", _jsx(_components.li, {
        children: "Added clipping to prevent an exploit boost."
      }), "\n", _jsx(_components.li, {
        children: "Slightly increased vent lighting."
      }), "\n", _jsx(_components.li, {
        children: "Minor but important change to shadowing in some rooms."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "August 1, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed visibility exploit around pipe at mid."
      }), "\n", _jsx(_components.li, {
        children: "More translation fixes."
      }), "\n", _jsx(_components.li, {
        children: "Fixed multiple one-way wallbanging issues."
      }), "\n", _jsx(_components.li, {
        children: "Made catwalks wallbangable."
      }), "\n", _jsx(_components.li, {
        children: "Fixed issue where grenades would get stuck on thin wall panels."
      }), "\n", _jsx(_components.li, {
        children: "Smoothed movement around generator for less abrupt movement restriction."
      }), "\n", _jsx(_components.li, {
        children: "Improved readability around Quad."
      }), "\n", _jsx(_components.li, {
        children: "Further improved readability at Heaven."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "November 6, 2013"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed lighting on prop in CT spawn."
      }), "\n", _jsx(_components.li, {
        children: "Fixed buggy wallbangs at A main and B halls."
      }), "\n", _jsx(_components.li, {
        children: "Fixed issue with dropping bomb behind spools in mid warehouse."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 26, 2013"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed bugs in Gwalior, Cache, Ali, Seaside and Agency."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
