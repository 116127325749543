import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 9, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed clipbrushes for Retakes."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 29, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added AWP wallbang spot from palace to scaffolding close corner, A site"
      }), "\n", _jsx(_components.li, {
        children: "Added bench in mid allowing jump up to window boost spot/peek towards cat"
      }), "\n", _jsx(_components.li, {
        children: "Widened T entrance to mid"
      }), "\n", _jsx(_components.li, {
        children: "Lowered trim around B site balcony"
      }), "\n", _jsx(_components.li, {
        children: "Blocked a jump-peek from middle of B site towards cat"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 13, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Removed pixel-gaps on boxes in bombsite A."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "August 29, 2018"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed texture blends in T-spawn area."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 6, 2018"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Adjusted player collision on bench wall at bombsite B."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "June 12, 2018"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Removed skybox limitations"
      }), "\n", _jsx(_components.li, {
        children: "Updated some landmark art"
      }), "\n", _jsx(_components.li, {
        children: "Lowered skillceiling to get into apartments from T spawn"
      }), "\n", _jsx(_components.li, {
        children: "Banked corner in hallway leading to A site scaffolding"
      }), "\n", _jsx(_components.li, {
        children: "Removed ladder near entrance to apartments from T spawn, replaced with boxes"
      }), "\n", _jsx(_components.li, {
        children: "Removed broken corner near van in bombsite B"
      }), "\n", _jsx(_components.li, {
        children: "Improved visibility from T side of mid towards A connector (removed dark background door inside connector)"
      }), "\n", _jsx(_components.li, {
        children: "Improved visibility from CT stairs towards T ramp in bombsite A (removed dark background door inside ramp)"
      }), "\n", _jsx(_components.li, {
        children: "Disabled bullet collision on vertical fence columns in mid"
      }), "\n", _jsx(_components.li, {
        children: "Removed wooden pillars at bottom of mid"
      }), "\n", _jsx(_components.li, {
        children: "Fixed some player collision bugs"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "June 9, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed pixelwalk in CT spawn"
      }), "\n", _jsx(_components.li, {
        children: "Fixed some small seethrough gaps in bombsite A"
      }), "\n", _jsx(_components.li, {
        children: "Fixed small seethrough gap from Short to Mid"
      }), "\n", _jsx(_components.li, {
        children: "Fixed grenadecollision on van in bombsite B"
      }), "\n", _jsx(_components.li, {
        children: "Van now plays proper surface sounds when walking on it"
      }), "\n", _jsx(_components.li, {
        children: "Stairs in palace and B apartments will now play correct surface sounds"
      }), "\n", _jsx(_components.li, {
        children: "Fixed potential rendering error on wall hole model used in CT sniper position"
      }), "\n", _jsx(_components.li, {
        children: "Fixed various bugs (Thanks csgobugtracker.com contributors!)"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "April 21, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Vandalism"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 17, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Removed wall-lamps that blocked visibility from ticketbooth to mid in bombsite A"
      }), "\n", _jsx(_components.li, {
        children: "Removed shelf inside market near bombsite B (thanks TheWhaleMan!)[citation needed]"
      }), "\n", _jsx(_components.li, {
        children: "Added a plywood board to bomb-crate in bombsite A to make throwing grenades under scaffolding more consistent"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 20, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed some exotic spots where C4 could get stuck (Thanks amitkilo!)"
      }), "\n", _jsx(_components.li, {
        children: "Moved palmtree behind ticketbox slightly, to make CTs easier to see."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "May 26, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed some spots where C4 could get stuck"
      }), "\n", _jsx(_components.li, {
        children: "Fixed some spots where players could get stuck on walls"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 28, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed a bug where a boosted player in T spawn could peek into mid/CT sniper position."
      }), "\n", _jsx(_components.li, {
        children: "Moved a prop to make peeking into palace easier, CT side."
      }), "\n", _jsx(_components.li, {
        children: "Removed a strange boost in bombsite A."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 8, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed a bug where players could clip through a wall near CT spawn"
      }), "\n", _jsx(_components.li, {
        children: "Removed center scaffolding near hole to CT sniper position"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 15, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed a boost exploit near T ramp in Bombsite A"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "August 27, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Removed various unintended boost-spots"
      }), "\n", _jsx(_components.li, {
        children: "Moved some flowers at top of mid"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 16, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed upper shutters in kitchen having a NODRAW face inside that wall that prevented wallbanging from one direction (bottom wood is unchanged)."
      }), "\n", _jsx(_components.li, {
        children: "Clipped a part behind van to prevent bomb from getting stuck."
      }), "\n", _jsx(_components.li, {
        children: "Thinned B “bench” wall a bit to allow more damage to penetrate."
      }), "\n", _jsx(_components.li, {
        children: "Clipped windows in palace so you can’t jump into them."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 10, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Updated look of inner middle window wall materials."
      }), "\n", _jsx(_components.li, {
        children: "Updated the Catwalk to Kitchen window bang to better visually designate what is bangable and what isn’t."
      }), "\n", _jsx(_components.li, {
        children: "Replaced the vent cover in window room with one that allows more visibility through it."
      }), "\n", _jsx(_components.li, {
        children: "Connecter to A wall now has a blown out, wood covered wall on the left to visually designate a bangable spot to allow more options for taking/retaking A."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 1, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added to the Active Duty maps group"
      }), "\n", _jsx(_components.li, {
        children: "Fixed a collision exploit near entrance to Underpass."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 5, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed a bug where you could peek over a crate at CT stairs."
      }), "\n", _jsx(_components.li, {
        children: "Added a peek position in Bombsite A connector, towards Palace exit."
      }), "\n", _jsx(_components.li, {
        children: "Clipped benches inside palace."
      }), "\n", _jsx(_components.li, {
        children: "Fixed some boost/exploit spots (Thanks F3RO!)."
      }), "\n", _jsx(_components.li, {
        children: "Breakable metal panels can no longer be shot through without triggering break effect."
      }), "\n", _jsx(_components.li, {
        children: "Made it easier to get into hole leading to CT sniper window."
      }), "\n", _jsx(_components.li, {
        children: "Made it easier to move over cart leading to B route from CT spawn."
      }), "\n", _jsx(_components.li, {
        children: "Reworked cover in Bombsite A."
      }), "\n", _jsx(_components.li, {
        children: "Reworked cover in Bombsite B."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 19, 2013"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Tweaked cover in Bombsite A."
      }), "\n", _jsx(_components.li, {
        children: "Removed trashcan near connector into Bombsite A."
      }), "\n", _jsx(_components.li, {
        children: "Added cover at top of CT stairs into Bombsite A."
      }), "\n", _jsx(_components.li, {
        children: "Widened box near T tunnel into Bombsite A."
      }), "\n", _jsx(_components.li, {
        children: "Blocked visibility under the van at Bombsite B."
      }), "\n", _jsx(_components.li, {
        children: "Removed the red pushcart near Bombsite B."
      }), "\n", _jsx(_components.li, {
        children: "Removed bed in apartments near Bombsite B."
      }), "\n", _jsx(_components.li, {
        children: "Removed center divider in CT sniper window."
      }), "\n", _jsx(_components.li, {
        children: "Added cover inside Palace."
      }), "\n", _jsx(_components.li, {
        children: "Removed scaffolding in alley near Apartments, tweaked skybox volume."
      }), "\n", _jsx(_components.li, {
        children: "Removed chairs in Underpass."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "June 26, 2013"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added more light to CT window into bombsite B."
      }), "\n", _jsx(_components.li, {
        children: "Fixed some graphical bugs."
      }), "\n", _jsx(_components.li, {
        children: "Removed small trashbags that could be mistaken for players."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "August 22, 2013"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Minor bugfixes."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "June 19, 2013"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed see-through crates at bombsites, various other bugfixes."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
