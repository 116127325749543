import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {appURLs} from "@/app/constants.mjs";
function _createMdxContent(props) {
  const _components = {
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Vertigo takes place on a high-rise construction site that introduces vertical combat and unique sound cues. Its standout feature is the risk of falling off the building, creating an additional layer of tension. Bombsite A offers a scenic overlook, while Bombsite B is positioned in an enclosed room. The map's towering construction framework and panoramic views make it one-of-a-kind in CS:GO's lineup."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite A:"
      }), " Positioned with a stunning overlook, Bombsite A features multiple entry points and a risk of falling off the building. The blend of indoor and outdoor spaces adds to the tactical depth."]
    }), "\n", _jsxs("div", {
      className: "images",
      children: [_jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/vertigo_a_site.webp`
      }), _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/vertigo_a_site_2.webp`
      })]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Bombsite B:"
      }), " Situated in an enclosed room with adjoining corridors, Bombsite B emphasizes close-quarters combat. The scaffolding and construction materials add to the site's thematic design."]
    }), "\n", _jsx("div", {
      className: "images",
      children: _jsx("img", {
        src: `${appURLs.CDN}/blitz/cs2/bombsites/vertigo_b_site.webp`
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
