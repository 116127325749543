import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mts";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { HeaderIcon } from "@/game-cs2-marketing/CommonComponents.jsx";
import { MAPS } from "@/game-cs2-marketing/constants.mts";
import { getMapImage } from "@/game-cs2-marketing/utils.mts";
import { GAME_SYMBOL_CS2 } from "@/game-csgo/definition-symbol.mjs";
import DataTable from "@/shared/DataTable.jsx";
import { WinRateText } from "@/shared-fps/CommonComponents.jsx";
import { displayRate } from "@/util/helpers.mts";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const MapCol = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-start;
  flex-grow: 1;

  span {
    margin-left: var(--sp-3);
  }
`;

function StatsMaps() {
  const { t } = useTranslation();
  const {
    cs2: {
      stats: { maps },
    },
  } = useSnapshot(readState);

  const cols = [
    {
      display: t("common:activeDuty", "Active Duty"),
      align: "left",
      primary: true,
    },
    {
      display: t("common:playRate", "Play Rate"),
      align: "right",
      isStat: true,
    },
    {
      display: t("csgo:stats.tWinRate", "T Win %"),
      align: "right",
      isStat: true,
    },
    {
      display: t("csgo:stats.ctWinRate", "CT Win %"),
      align: "right",
      isStat: true,
    },
    { display: t("common:matches", "Matches"), align: "right", isStat: true },
  ];
  const rows = Object.entries(maps || {})
    .filter(([mapId]) => MAPS[mapId]?.activeDuty)
    .map(([mapId, stats]) => {
      return [
        {
          display: (
            <MapCol>
              <HeaderIcon src={getMapImage(mapId)} />
              <span>{t(MAPS[mapId]?.i18nKey, MAPS[mapId]?.name || mapId)}</span>
            </MapCol>
          ),
          value: mapId,
          link: `/${GAME_SHORT_NAMES[GAME_SYMBOL_CS2]}/database/maps/${mapId}/overview`,
        },
        {
          display: displayRate(stats.pick_rate * 100, 100, 0, 1),
          value: stats.pick_rate,
        },
        {
          display: (
            <WinRateText
              className={"type-body2"}
              $score={stats.t_win_rate * 100}
            >
              {displayRate(stats.t_win_rate * 100, 100, 0, 1)}
            </WinRateText>
          ),
          value: stats.t_win_rate,
        },
        {
          display: (
            <WinRateText
              className={"type-body2"}
              $score={stats.ct_win_rate * 100}
            >
              {displayRate(stats.ct_win_rate * 100, 100, 0, 1)}
            </WinRateText>
          ),
          value: stats.ct_win_rate,
        },
        {
          display: getLocaleString(stats.num_matches),
          value: stats.num_matches,
        },
      ];
    });
  return (
    <DataTable
      cols={cols}
      rows={rows}
      sortDir="DESC"
      sortCol={1}
      sortColTiebreak={4}
      indexCol
    />
  );
}

export default StatsMaps;
