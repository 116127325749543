import type { ComponentType } from "react";

import AncientOverview from "@/game-cs2-marketing/map-overviews/de_ancient.mdx";
import AnubisOverview from "@/game-cs2-marketing/map-overviews/de_anubis.mdx";
import CacheOverview from "@/game-cs2-marketing/map-overviews/de_cache.mdx";
import CanalsOverview from "@/game-cs2-marketing/map-overviews/de_canals.mdx";
import CobblestoneOverview from "@/game-cs2-marketing/map-overviews/de_cbble.mdx";
import Dust2Overview from "@/game-cs2-marketing/map-overviews/de_dust2.mdx";
import InfernoOverview from "@/game-cs2-marketing/map-overviews/de_inferno.mdx";
import MirageOverview from "@/game-cs2-marketing/map-overviews/de_mirage.mdx";
import NukeOverview from "@/game-cs2-marketing/map-overviews/de_nuke.mdx";
import OverpassOverview from "@/game-cs2-marketing/map-overviews/de_overpass.mdx";
import TrainOverview from "@/game-cs2-marketing/map-overviews/de_train.mdx";
import TuscanOverview from "@/game-cs2-marketing/map-overviews/de_tuscan.mdx";
import VertigoOverview from "@/game-cs2-marketing/map-overviews/de_vertigo.mdx";

type Overviews = {
  [key: string]: ComponentType;
};

const MAP_OVERVIEWS: Overviews = {
  de_mirage: MirageOverview,
  de_dust2: Dust2Overview,
  de_inferno: InfernoOverview,
  de_overpass: OverpassOverview,
  de_nuke: NukeOverview,
  de_ancient: AncientOverview,
  de_anubis: AnubisOverview,
  de_vertigo: VertigoOverview,
  de_cache: CacheOverview,
  de_cbble: CobblestoneOverview,
  de_train: TrainOverview,
  de_tuscan: TuscanOverview,
  de_canals: CanalsOverview,
};

export default MAP_OVERVIEWS;
